<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:35:07
-->
<template>
  <div class="index" v-loading="loading">
    <Parser v-if="inited"  :pageType="pageType" :isNormal="true"></Parser>
  </div>
</template>

<script>
import Parser from '@/components/parser/Index';
import { pageFromDetail } from '@/api/saas'
import { isJSONStr } from '@/utils/tools';

export default {
  name: 'Index',
  components: {
    Parser
  },
  data() {
    return {
      inited: false,
      loading: false,
      pageUUID: null,
      pageType: ''
    }
  },
  created() {
    this.getViewData();
  },
  watch: {
    '$route'() {
      const pageUUID = this.$route.query.pageUUID;
      if (this.pageUUID === pageUUID) return;
      this.reRender();
    }
  },
  methods: {
    /**
		 * @desc: 获取dataview配置数据
		 */
		getViewData() {
			this.loading = true;
      this.pageUUID = this.$route.query.pageUUID;
			pageFromDetail({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					const { page_data } = res?.data?.data?.[0] || {};
          const { componentData, editorConfig, editorType, statusConfig = [] } = isJSONStr(page_data ) ? JSON.parse(page_data) : page_data;
          this.pageType = editorType
          sessionStorage.setItem('statusConfig', JSON.stringify(statusConfig));
          this.$store.commit('setConfigData', {
            componentData,
            editorConfig,
            editorType
          });
          sessionStorage.setItem('editorType', editorType);
          this.inited = true;
				})
				.catch(() => {
					this.loading = false;
				})
    },
    /**
     * @desc: 重新渲染
     */
    reRender() {
      this.inited = false;
      this.getViewData();
    }
  }
}
</script>

<style lang="less" scoped>
.index{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>